/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.background {
  background: url("/assets/img/login_bg.jpg") no-repeat bottom left;
  background-size: cover;
  background-color: $black;
  width: 100%;
  // 4rem is Navbar height
  height: calc(100vh - 4rem);
  display: flex;

  & > div {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  @include for-phone-only {
    flex-direction: column;
  }
}

.container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  .title {
    color: $white;
    font-weight: 280;
    margin-bottom: 180px;
  }

  .loginBox {
    background-color: #fff;
    padding: 3rem;
    width: 420px;

    @include for-phone-only {
      width: auto;
    }
  }
}
