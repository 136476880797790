/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.select {
  padding: 0.7em;
  cursor: pointer;
  border: solid 1px $primary;
  background: #fff;

  &:focus {
    outline: 1px solid $gray3;
    outline-offset: 2px;
  }
}
