/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.container {
  $color: color.scale($red, $lightness: 10%, $saturation: -5%);
  width: 100%;
  min-height: 350px;
  border: dashed 3px $color;
  color: $color;
  display: flex;
  justify-content: center;
  align-items: center;
  .inner {
    width: 75%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include for-phone-only {
      width: 90%;
    }
    .retryButton {
      border: none;
      text-decoration: underline;
      color: $color;
      cursor: pointer;
      padding: 0;
    }
  }
  svg {
    stroke: $color;
    flex-basis: 100px;
  }
}
