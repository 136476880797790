/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

/*
  SLIDE
*/
.slide-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.slide-exit {
  opacity: 1;
  transform: translateX(0%);
}
.slide-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.slide-enter-active,
.slide-exit-active {
  transition: opacity 500ms, transform 500ms;
}

/*
  FADE
*/
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

/*
  APEAR
*/
.apear-enter {
  opacity: 0;
  transform: translateY(100px);
}
.apear-enter-active {
  opacity: 1;
  transform: translateY(0px);
}
.apear-exit {
  opacity: 1;
  transform: translateY(0px);
}
.apear-exit-active {
  opacity: 0;
  transform: translateY(100px);
}
.apear-enter-active,
.apear-exit-active {
  transition: opacity 0.25s, transform 0.25s;
}
