/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.container {
  display: flex;
  flex-direction: row-reverse;
  gap: 4em;

  .perPageButtons {
    cursor: pointer;
    border: none;
    background: none;
    padding: 2px;
    font-weight: 500;
  }

  .pagination {
    ul {
      margin: 0px;
      padding: 0px;
      display: flex;
      gap: 0.7rem;
      align-items: center;
      li {
        list-style: none;
        cursor: pointer;

        svg {
          stroke: $primary;
        }

        a {
          text-decoration: none;
        }

        &.break {
          text-decoration: none;
        }

        &.selected {
          a {
            cursor: auto;
            color: $black;
            font-weight: bold;
          }
        }

        &.disabled {
          a {
            svg {
              cursor: auto;
              stroke: $black;
            }
          }
        }
      }
    }
  }
}
