/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.container {
  color: $gray5;
  // border: dashed 1px $gray3;
  // background-color: $gray2;
  // padding: 1rem;
  display: flex;
  gap: 0.5rem;

  .icon {
    flex: 0 0 30px;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .body {
    flex: 1;

    .title {
      line-height: 1;
      margin-bottom: 0.25rem;
    }

    .bodyText {
    }
  }

  &.error {
    color: $red;
    // background-color: color.scale($red, $lightness: 85%, $saturation: -25%);
    border-color: $red;
    .icon {
      svg {
        stroke: $red;
      }
    }
  }

  &.warning {
    color: $amber;
    // background-color: color.scale($amber, $lightness: 85%, $saturation: -25%);
    border-color: $amber;
    .icon {
      svg {
        stroke: $amber;
      }
    }
  }

  &.success {
    color: $green;
    // background-color: color.scale($green, $lightness: 85%, $saturation: -50%);
    border-color: $green;
    .icon {
      svg {
        stroke: $green;
      }
    }
  }
}
