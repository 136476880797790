/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.container {
  width: 100%;
  display: flex;
  align-items: center;
  &.none {
    height: 1px;
  }
  &.xs {
    height: 0.5rem;
  }
  &.sm {
    height: 1rem;
  }
  &.md {
    height: 2rem;
  }
  &.lg {
    height: 3rem;
  }
  &.xl {
    height: 4rem;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: $gray2;
  }
}
