/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.space {
  width: 100%;
  &.xs {
    height: 0.5rem;
  }
  &.sm {
    height: 1rem;
  }
  &.md {
    height: 2rem;
  }
  &.lg {
    height: 3rem;
  }
  &.xl {
    height: 4rem;
  }
}
