/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/fonts" as *;

.helpContainer {
  padding: 2% 8%;
  min-height: 90vh;
}
.paragraph {
  font-size: $navbar1;
  margin: 0.5rem 0 0;
}
.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.formSection {
  margin: 2rem 0;
  width: 45%;
}
.formDropdown {
  margin: 1rem 1rem 1rem 0;
  font-size: $navbar1;
  width: 40%;
  display: flex;
  flex-direction: column;
}
.formFeedback {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.formItem {
  margin: 1rem 0;
  font-size: $navbar1;
  display: flex;
  flex-direction: column;
}
.formLabel {
  font-size: $navbar1;
  width: max-content;
  order: -1;
}
.formControl {
  font-size: $input;
  padding: 0.6rem;
  /* 
        Only change form label to top when either focused,
        or while not focused and a value is entered 
    */
  &:focus ~ .formLabel,
  &:not(:focus):valid ~ .formLabel {
    top: 0;
    left: 0;
    background: transparent;
  }
  &:focus:invalid {
    border-color: red;
    box-shadow: 0 0 0 0.25rem rgba(253, 13, 13, 0.25);
  }
}
@media only screen and (max-width: 768px) {
  .formSection,
  .formFeedback {
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .formDropdown {
    width: 100%;
  }
}
