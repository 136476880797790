/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.root {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.container {
  background-color: $white;
  min-width: 500px;
  margin: 80px auto;
  width: 700px;
  overflow: hidden;
  transform-origin: center center;
  animation-duration: 0.25s;
  animation-name: slideIn;

  @include for-phone-only {
    width: 100%;
    min-width: 100%;
    height: 100vh;
    margin: 0;
    border-radius: 0;
    padding: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 3rem;
    padding-bottom: 0;

    .title {
      display: flex;
      gap: 0.5rem;
      align-items: flex-end;

      @include for-phone-only {
        font-size: 1.5rem;
      }

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .close {
      border: none;
      cursor: pointer;
      background-color: transparent;
    }
  }

  .content {
    padding: 3rem;
    padding-top: 0;
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    @include for-phone-only {
      max-height: 100vh;
      overflow-x: auto;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
