/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.accordionTitle {
  padding: 1rem;
  color: $white;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  svg {
    stroke: $white;
  }
}
.toggler {
  color: $white;
  transition: all 0.35s ease;
}
.contentBox {
  overflow: hidden;
  background-color: $white;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  margin-bottom: 1px;
}
.contentBox[aria-expanded="false"] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.contentText {
  margin: 1rem;
}
