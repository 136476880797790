/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.footer {
  padding: 3rem 2rem;
  & > div {
    margin: 0px auto;
    max-width: $max-page-width;
    color: rgb(150, 150, 150);
    font-size: 0.7rem;
  }
}
