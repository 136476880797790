/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;
@use "styles/fonts" as *;

.navMenu {
  position: relative;
  background-color: $white;
  color: $black;
  padding: 0 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  height: 4rem;
  .navLogo {
    width: 8.5rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
  }
  ul {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    li {
      a {
        height: 4rem;
        display: flex;
        align-items: center;
        align-content: center;
        padding: 0 1rem;
        font-size: 0.7rem;
        font-weight: bold;
        letter-spacing: -1;
        transition: background-color 0.25s;
        &:hover {
          background-color: $gray;
        }
        &.isActive {
          //
        }
      }
    }
  }

  .logoutBtn {
    cursor: pointer;
    margin-left: auto;
    position: relative;
    .logoutDropdown {
      position: absolute;
      top: 3rem;
      left: 0;
      right: 0;
      height: 2rem;
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }

  @include for-phone-only {
    ul {
      background-color: $primary;
      width: 100%;
      // 4rem is Navbar height
      height: calc(100vh - 4rem);
      position: absolute;
      top: 4rem;
      left: -100%;
      padding: 2rem;
      transition: 0.25s all;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      li {
        a {
          color: $white;
          font-size: 1.5rem;
          &:hover {
            background-color: $primary;
          }
        }
      }
    }
    .visible {
      left: 0;
    }
    .active {
      position: fixed;
      z-index: 100;
    }
    .toggleMenu {
      display: inline-block;
    }
    .logoutBtn {
      margin-left: 0px;
      svg {
        stroke: $white;
      }
      .logoutDropdown {
        background-color: $white;
        color: $primary;
        font-size: 1rem;
      }
    }
  }
}

.toggleMenu {
  display: none;
  border: none;
  background-color: transparent;
  margin-left: auto;
  font-size: $toggle;
}
