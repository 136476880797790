/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.input {
  color: $font-color;
  height: 2.2rem;
  padding: 0rem 0.5rem;
  border: 1px solid $gray2;
  &.sm {
    padding: 0rem 0.5rem;
    font-size: 0.9rem;
    height: 1.8rem;
  }
  &.hasError {
    border-color: $red;
  }
  &::-webkit-input-placeholder {
    color: $gray2;
  }
  &:focus {
    outline: 1px solid $gray3;
    outline-offset: 2px;
  }
}
