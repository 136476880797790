/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.success {
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    width: 150px;
    height: 150px;
    stroke: $green;
    transform: scale(0);
    animation: bounceIn 1.2s ease forwards;
    animation-delay: 0.5s;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}
