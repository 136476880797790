/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.group {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  &.xs {
    gap: 0.5rem;
  }
  &.sm {
    gap: 1rem;
  }
  &.md {
    gap: 2rem;
  }
  &.lg {
    gap: 3rem;
  }
  &.xl {
    gap: 4rem;
  }
}
