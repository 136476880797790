/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.vehicleRow {
  background-color: #fff;
  padding: 0.5rem 1rem;
  display: flex;
  align-content: center;
  justify-content: space-between;
  border-bottom: 1px solid $gray;
  & > div {
    display: flex;
    align-content: center;
    gap: 1rem;
  }
}

.centerCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeBtn {
  border: none;
  background: none;
  cursor: pointer;
  min-width: 40px;
}

.typyingTable {
  tr {
    th {
      padding-top: 0;
      cursor: auto;
    }
    td {
      padding: 0.5rem 0;
    }
    &:last-child {
      td {
        padding-bottom: 0;
      }
    }
    &.hasError {
      td {
        .centerCell {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
