/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.switch {
  $width: 50px;
  $height: 30px;
  $slider-length: $height - 6;

  position: relative;
  display: inline-block;
  width: $width;
  height: $height;

  &[data-focus="true"] {
    outline: 1px solid $gray3;
    outline-offset: 2px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $gray3;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $gray3;
    }

    &:checked + .slider {
      background-color: $black;
    }

    &:checked + .slider:before {
      transform: translateX($width - $slider-length - 4);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray3;
    border-radius: $height;
    transition: all 0.25s;

    &:before {
      position: absolute;
      content: "";
      height: $slider-length;
      width: $slider-length;
      left: 2px;
      top: 3px;
      background-color: $white;
      border-radius: 50%;
      transform: translateX(0);
      transition: all 0.25s;
    }
  }
}
