/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  label {
    cursor: pointer;
    font-weight: 500;
  }
}
.error {
  margin-top: 0.5rem;
  color: red;
  font-size: 0.8rem;
}
