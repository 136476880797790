/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "vars" as *;

.btn {
  background-color: $primary;
  color: $white;
  padding: 0rem 1rem;
  height: 2.4rem;
  min-width: 160px;
  border: none;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  transition: all 0.2s;
  line-height: 1.2rem;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;

  &.ghost {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    &:hover {
      background-color: color.scale($primary, $lightness: 8%);
      border-color: color.scale($primary, $lightness: 8%);
    }
    .icon {
      opacity: 0.8;
    }
  }

  &.secondary {
    background-color: $secondary;
    &:hover {
      background-color: color.scale($secondary, $lightness: 8%);
    }
  }

  &.success {
    background-color: $green;
    &:hover {
      background-color: color.scale($green, $lightness: 8%);
    }
  }

  &.danger {
    background-color: $red;
    &:hover {
      background-color: color.scale($red, $lightness: 8%);
    }
  }

  &:hover {
    background-color: color.scale($primary, $lightness: 8%);
    color: #fff;
  }

  &:active {
    transform: scale(0.99);
    background-color: color.scale($primary, $lightness: -5%);
  }

  &:focus {
    outline: 1px solid $gray3;
    outline-offset: 2px;
  }

  .text {
    overflow-wrap: break-word;
  }

  .icon {
    min-width: 25px;
    svg {
      width: 25px;
      height: 25px;
      stroke: $white;
      fill: $white;
    }
  }

  &:disabled {
    background-color: $gray4;
    color: $gray10;
    cursor: not-allowed;

    .icon {
      svg {
        stroke: $gray5;
        fill: $gray5;
        path {
          fill: $gray5;
        }
      }
    }

    &:active {
      transform: none;
      background-color: $gray4;
    }
  }
}
