/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

.container {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1000;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  transition: all 1s;
  .notification {
    width: 350px;
    padding: 0.5rem;
    background: $white;
    position: relative;
    animation: slideIn 0.4s;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.25);
    & > div {
      padding: 0.5rem;
      border-left: solid 4px $primary;
      .title {
        display: flex;
        gap: 0.2rem;
        align-items: center;
      }
      svg {
        stroke: $primary;
      }
    }
    &.animateOut {
      animation: slideOut 0.2s forwards;
      animation-timing-function: ease-in-out;
    }
    &.error {
      & > div {
        border-left-color: $red;
        .title {
          color: $red;
        }
        svg {
          stroke: $red;
        }
      }
    }
    &.success {
      & > div {
        border-left-color: $green;
        .title {
          color: $green;
        }
        svg {
          stroke: $green;
        }
      }
    }

    .close {
      border: none;
      cursor: pointer;
      opacity: 0.6;
      transition: opacity 0.2s;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(100px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }
  to {
    opacity: 0;
    transform: translate(100px, 0);
  }
}
