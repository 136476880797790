/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";

$primary: #1e1e1e;
$secondary: #1e1e1e;

$white: #fff;
$black: #1e1e1e;

$gray: #e8e8e8;
$gray2: #c4c4c4;
$gray3: color.scale($gray, $lightness: -30%);
$gray4: #c4c4c4;
$gray5: color.scale($gray, $lightness: -60%);
$gray6: color.scale($gray, $lightness: -80%);

$gray10: #525252;

$red: #da000a;
$green: #0a874b;
$amber: rgb(201, 139, 22);

$font-color: $primary;

$max-page-width: 1200px;

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
