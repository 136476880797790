/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "vars" as *;

.table {
  width: 100%;
  border-collapse: collapse;
  border: solid 1px $gray2;

  tr {
    th {
      color: $black;
      text-align: left;
      padding: 1rem 0.5rem;
      cursor: pointer;
      user-select: none;
      font-weight: 550;

      &:first-child {
        padding-left: 2rem;
      }
      &:last-child {
        padding-left: 2rem;
      }

      div {
        display: flex;
        align-items: center;
        gap: 0.2rem;
      }

      svg {
        width: 1rem;
        opacity: 1;
      }
    }
    td {
      background-color: $white;
      padding: 1rem 0.5rem;
      border-top: 1px solid $gray2;

      &:first-child {
        padding-left: 2rem;
      }

      &:last-child {
        padding-left: 2rem;
      }

      svg {
        width: 1.4rem;
      }
    }
    &:hover {
      td {
      }
    }

    &:last-child {
      td {
        border: none;
      }
    }

    &:nth-child(odd) {
      td {
        background-color: $gray;
      }
    }
  }

  &.clean {
    border: none;
    tr {
      td {
        background-color: transparent;
        border-top: none;
        padding: 0.5rem 0;
      }

      th {
        padding: 1rem 0;
      }

      &:hover {
        td {
          background-color: transparent;
        }
      }
      &:first-child {
        td {
          border-top: 1px solid $gray2;
        }
      }
    }
  }

  &.lg {
    tr {
      td {
        padding: 0.75rem 0;
      }
    }
  }
}
