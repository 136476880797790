/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "styles/vars" as *;

/* Hide the browser's default checkbox */
.hiddenInput {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}
.checkbox {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $primary;
  border: solid 1px $white;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 0.5rem;
  position: relative;
  // Positions checkbox before its label
  order: -1;
  svg {
    stroke: #fff;
    display: none;
  }
  &[data-checked="true"] {
    background-color: $primary;
    border-color: $primary;
    &::after {
      content: "";
      background-color: $primary;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      animation: animate 0.5s forwards;
      transform-origin: right;
    }
    svg {
      display: block;
    }
  }
  &[data-focus="true"] {
    outline: 1px solid $gray3;
    outline-offset: 2px;
  }
}

@keyframes animate {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
