/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

$font-fallback: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova-Regular"),
    url("../assets/fonts/ProximaNova-Regular.otf") format("otf");
  font-display: swap;
}

@font-face {
  font-family: "JLREmericBold";
  src: local("JLREmeric-SemiBold"),
    url("../assets/fonts/JLREmeric-SemiBold.otf") format("otf");
  font-display: swap;
}

@font-face {
  font-family: "JLREmeric";
  src: local("JLREmeric-ExtraLight"),
    url("../assets/fonts/JLREmeric-ExtraLight.otf") format("otf");
  font-display: swap;
}

$navbar1: calc(0.5vw + 8px);
$navbar2: calc(0.5vw + 6px);
$navbar3: calc(4.2vw + 8px);
$toggle: 1.6rem;
$input: calc(0.4vw + 8px);
