/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "styles/vars" as *;

.container {
  background-color: $gray;
  width: 100%;
  min-height: 350px;
  border: dashed 3px $gray3;
  color: $gray3;
  display: flex;
  justify-content: center;
  align-items: center;
  .inner {
    width: 75%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    @include for-phone-only {
      width: 90%;
    }
  }
  svg {
    stroke: $gray3;
    flex-basis: 70px;
  }
}
