/*
 * Copyright 2022 (c) Jaguar Land Rover Ltd. All rights reserved.
 */

@use "sass:color";
@use "vars" as *;
@use "fonts" as *;
@use "animations" as *;
@use "transitions" as *;
@use "defaults";
@use "table";
@use "button";

html {
  font-size: 16px;
}

body {
  color: $font-color;
  background-color: $white;
  font-weight: 400;
}

svg {
  //stroke: $font-color;
  fill: $font-color;
}

a,
a:active {
  color: $primary;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "JLREmeric", $font-fallback;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1rem;
}

.pointer {
  cursor: pointer;
}

.card {
  background-color: $white;
  padding: 1rem;
  border: solid 1px $black;
}

.flex {
  display: flex;
}

.gap {
  width: 100%;
  height: 1rem;
  &.sm {
    height: 0.5rem;
  }
  &.lg {
    height: 2rem;
  }
}

.iconBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.red-color {
  color: $red;
  stroke: $red;
}

.green-color {
  color: $green;
  stroke: $green;
}

.primary-color {
  color: $primary;
  stroke: $primary;
}

.secondary-color {
  color: $secondary;
  stroke: $secondary;
}

.bold {
  font-weight: bold;
}
